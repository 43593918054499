<template>
  <div class="page store profit settlement">
    <div class="ibox ">

      <div class="ibox-content">

        <div class="table-responsive">
          <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
            <thead>

              <tr class="fields text-left">
                <th :colspan="columns">
                  <div class="form-group row"><label class="col-sm-2 col-form-label">상점 ID</label>
                    <div class="col-sm-10">
                      <StoreSelect v-model="store_id" name="store_id" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">환율 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="exchange_rate" v-model="exchange_rate" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">호스트 수익률 (%)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="profit_rate" v-model="profit_rate" />
                    </div>
                  </div>

                  <!-- <div class="form-group row"><label class="col-sm-2 col-form-label">주문당 제반비용 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="expenses_per_order" v-model="expenses_per_order" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">주문당 제반비용 내용</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" :rows="3" name="expenses_per_order_comment" v-model="expenses_per_order_comment" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">취소당 제반비용 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="expenses_per_cancel" v-model="expenses_per_cancel" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">취소당 제반비용 내용</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" :rows="3" name="expenses_per_cancel_comment" v-model="expenses_per_cancel_comment" />
                    </div>
                  </div> -->

                  <div class="form-group row"><label class="col-sm-2 col-form-label">기타 제반비용 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="expenses_etc" v-model="expenses_etc" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">기타 제반비용 내용</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" :rows="3" name="expenses_etc_comment" v-model="expenses_etc_comment" />
                    </div>
                  </div>

                  <div class="form-group row right">
                    <div class="date-range-input">
                      <div class="input-group">
                        <div class="input">
                          <datetime v-model="date_start" type="date" zone="Asia/Seoul" name="date_start" :week-start="0" placeholder="정산 시작일" />
                          <div class="divine"></div>
                          <datetime v-model="date_end" type="date" zone="Asia/Seoul" name="date_end" :week-start="0" placeholder="정산 종료일" />
                        </div>
                        <span class="input-group-append">
                          <button type="button" class="btn btn-submit" @click="onSubmit">Search</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <thead v-if="profitData">
              <tr v-if="latest">
                <td :colspan="columns">
                  <h3>마지막으로 정산완료된 내역입니다.</h3>
                </td>
              </tr>
              <tr>
                <th :colspan="columns">
                  <div class="info">Showing {{page}} to {{Math.max(1, parseInt(total/size))}} of <strong>{{total}}</strong> entries</div>
                  <div class="padding"></div>
                </th>
              </tr>
              <tr class="fields">
                <th scope="col">#</th>
                <th scope="col">결제시간</th>
                <th scope="col">결제방식</th>
                <th scope="col">결제단위</th>
                <th scope="col">결제금액</th>
                <th scope="col">취소시간</th>
                <th scope="col">주문상태</th>
                <!-- <th scope="col">티켓상태</th> -->
              </tr>
            </thead>
  

            <tbody v-if="profitData">
              <tr v-for="row in items" :key="row.uid" :class="{'has-settlement': row.has_settlement}">
                <td>{{row.uid}}</td>
                <td>{{row.paid_at && $moment.tz(row.paid_at, "UTC").tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")}}</td>
                <td>{{row.payment_method}}</td>
                <td>{{row.paid_unit}}</td>
                <td>{{row.paid_total}}</td>
                <td>{{row.cancelled_at && $moment.tz(row.cancelled_at, "UTC").tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")}}</td>
                <td>{{row.display_status}}</td>
                <!-- <td>{{row.display_ticket_status}}</td> -->
              </tr>
              <tr v-if="profitData.has_settlements || items.length === 0">
                <td :colspan="columns" class="errors">
                  <p v-if="profitData.has_settlements">정산 내역이 존재합니다. 기존 정산을 처리 후에 생성 가능합니다.</p>
                  <p v-if="items.length === 0">정산할 주문 내역이 없습니다.</p>
                </td>
              </tr>
            </tbody>

            <tbody v-if="profitData">
              <tr>
                <td :colspan="columns" class="text-left" >
                  <h3>달러 주문건 </h3>

                  <h4>주문 합계</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문금액</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.ordered)}}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제금액</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.paid)}}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소금액</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.cancelled)}}</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.profit)}}</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">
                  <h4>건수 / 수량</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문건수</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_quantity)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_over_quantity)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제건수</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.paid_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_quantity_paid)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_over_quantity_paid)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소건수</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.cancel_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_quantity_cancel)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_over_quantity_cancel)}} 개</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>주문당 제반비용</dt> <dd>{{profitData.extras.usd.order_count}} 개 x {{profitData.expenses_per_order}} 원 = {{$utils.numberFormat(profitData.extras.usd.expenses_per_order)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>취소당 제반비용</dt> <dd>{{profitData.extras.usd.cancel_count}} 개 x {{profitData.expenses_per_cancel}} 원 = {{$utils.numberFormat(profitData.extras.usd.expenses_per_cancel)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>PG 수수료</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.payment_fee)}}</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
            </tbody>

            <tbody v-if="profitData">
              <tr>
                <td :colspan="columns" class="text-left" >
                  <h3>원화 주문건 </h3>

                  <h4>주문 합계</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문금액</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.ordered)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제금액</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.paid)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소금액</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.cancelled)}} 원</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.profit)}} 원</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">
                  <h4>건수 / 수량</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문건수</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_quantity)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_over_quantity)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제건수</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.paid_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_quantity_paid)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_over_quantity_paid)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소건수</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.cancel_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_quantity_cancel)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_over_quantity_cancel)}} 개</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>주문당 제반비용</dt> <dd>{{profitData.extras.krw.order_count}} 개 x {{profitData.expenses_per_order}} 원 = {{$utils.numberFormat(profitData.extras.krw.expenses_per_order)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>취소당 제반비용</dt> <dd>{{profitData.extras.krw.cancel_count}} 개 x {{profitData.expenses_per_cancel}} 원 = {{$utils.numberFormat(profitData.extras.krw.expenses_per_cancel)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>PG 수수료</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.payment_fee)}} 원</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
            </tbody>

            <tfoot v-if="profitData">
              <tr>
                <td :colspan="columns" class="text-left" >
                  <h3>총 합계 </h3>

                  <h4>주문 합계</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문금액</dt> <dd>{{$utils.numberFormat(profitData.total_ordered)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제금액</dt> <dd>{{$utils.numberFormat(profitData.total_paid)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소금액</dt> <dd>{{$utils.numberFormat(profitData.total_cancelled)}} 원</dd>
                      </dl>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">

                  <h4>제반비용 </h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>PG 결제 수수료</dt> <dd>{{$utils.numberFormat(profitData.total_payment_fee)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>기타 제반비용</dt> <dd>{{$utils.numberFormat(profitData.expenses_etc)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>호스트 부담 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_basic)}} 원</dd>
                      </dl>
                      <!-- <dl>
                        <dt>주문당 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_per_order)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>취소당 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_per_cancel)}} 원</dd>
                      </dl> -->
                    </li>
                    <li>
                      <dl>
                        <dt>총 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses)}} 원</dd>
                      </dl>
                    </li>
                  </ul>

                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">

                  <h4>호스트 수익</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>총 매출</dt> <dd>{{$utils.numberFormat(profitData.total_paid)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>공동 부담 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_payment_fee + profitData.expenses_etc)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>총 수익</dt> <dd>{{$utils.numberFormat(profitData.total_paid-profitData.total_payment_fee-profitData.expenses_etc)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>수익률</dt> <dd>{{profitData.profit_rate}} %</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(profitData.total_profit)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>호스트 부담 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_basic)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>호스트 수익</dt> <dd>{{$utils.numberFormat(profitData.total_income)}} 원</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>세금</dt> <dd>{{$utils.numberFormat(profitData.total_income_tax)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>공급가액</dt> <dd>{{$utils.numberFormat(profitData.total_income_amount)}} 원</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="actions">
                  <div class="left">
                    <button class="btn btn-white btn-sm" @click="onClickBack">Back</button>
                  </div>
                  <div class="right">
                    <button type="button" class="btn btn-primary btn-submit" :disabled="!canSettle" @click="onClickSettlement">정산데이타 생성</button>
                  </div>
                </td>
              </tr>
            </tfoot>

          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/elements/Pagination"
import SearchInput from "@/components/utils/SearchInput"
import StoreSelect from "@/components/utils/StoreSelect"

export default {
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model

    this.loadData()
    this.loadLatest()
  },
  data() {
    let exchangeRate = this.$store.getters['store/exchangeRate'] || 1100

    return {
      columns: 8,
      mode: 'unset',
      model: {},
      page: 1,
      size: 10,
      total: 0,
      data: {},
      items: [],
      profitData: null,
      store_id: this.$route.params.uid || 0,
      expenses_etc: 0,
      expenses_etc_comment: '',
      expenses_per_order_comment: '',
      expenses_per_order: 0,
      expenses_per_cancel_comment: '',
      expenses_per_cancel: 0,
      exchange_rate: exchangeRate,
      profit_rate: 50.0,
      latest: false,
      date_start: '',
      date_end: '',
    }
  },
  watch: {
    exchange_rate(value, oldValue) {
      if (value) {
        this.$store.dispatch('store/setExchangeRate', {
          exchange_rate: value
        })
      }
    }
  },
  computed: {
    canSettle() {
      return !this.latest && !this.profitData.has_settlements && this.items.length > 0
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(this.model.types.get.action, this.$route.params)
        .then((data) => {
          this.$data.data = data
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    loadLatest() {
      let params = {
        store_id: this.$route.params.uid
      }

      this.$store.dispatch(this.model.types.latestSettlement.action, params)
        .then((data) => {
          let { profit_rate, expenses_per_order, expenses_per_cancel, exchange_rate, items } = data
          this.$data.items = items
          this.$data.total = items.length
          this.$data.profitData = data
          this.$data.latest = true

          this.$data.expenses_per_order = expenses_per_order
          this.$data.expenses_per_cancel = expenses_per_cancel
          this.$data.exchange_rate = exchange_rate
          this.$data.profit_rate = profit_rate

        })
        .catch(e => {
          // this.$toast.error(e.message)
        })
    },

    onClear() {

    },
    onPage() {

    },
    onSubmit() {
      let params = { 
        uid: this.store_id + '', 
        date_start: this.$moment.tz(this.date_start, "Asia/Seoul").format("YYYY-MM-DD"), 
        date_end: this.$moment.tz(this.date_end, "Asia/Seoul").format("YYYY-MM-DD"), 
        exchange_rate: this.exchange_rate,
        profit_rate: this.profit_rate,
        expenses_per_order: this.expenses_per_order,
        expenses_per_cancel: this.expenses_per_cancel,
        expenses_etc: this.expenses_etc
      }

      this.$store.dispatch(this.model.types.profit.action, params)
        .then((data) => {
          let { items } = data
          this.$data.items = items
          this.$data.total = items.length
          this.$data.profitData = data
          this.$data.latest = false
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onClickBack() {
      this.$router.back()
    },

    onClickSettlement() {
      let params = { 
        store_id: this.store_id + '', 
        date_start: this.$moment.tz(this.date_start, "Asia/Seoul").format("YYYY-MM-DD"), 
        date_end: this.$moment.tz(this.date_end, "Asia/Seoul").format("YYYY-MM-DD"), 
        exchange_rate: this.exchange_rate,
        profit_rate: this.profit_rate,
        expenses_per_order: this.expenses_per_order,
        expenses_per_order_comment: this.expenses_per_order_comment,
        expenses_per_cancel: this.expenses_per_cancel,
        expenses_per_cancel_comment: this.expenses_per_cancel_comment,
        expenses_etc: this.expenses_etc,
        expenses_etc_comment: this.expenses_etc_comment
      }

      this.$store.dispatch(this.model.types.settlement.action, params)
        .then((data) => {

          this.$toast.success("생성하였습니다.")
          let { uid } = data

          this.$router.push({
            path: `/settlement/manage/${uid}`
          })
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

  },
  components: {
    Pagination, SearchInput, StoreSelect
  }
}
</script>
